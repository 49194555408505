<template>
  <div>
    <div class="rqltltx">
      <div class="bgmain">
        <div class="title">{{ projectData.project }}脱硫脱硝系统</div>
        <div class="row1-main flex">
          <!-- 左侧部分 -->
          <div class="leftmain">
            <div class="flex">
              <div class="leftrow1">炉膛口</div>
              <div
                class="leftrow1-right"
                style="cursor: pointer"
                @click="
                  toCompon(
                    2,
                    'ANOX_Y_B',
                    infoList.ANOX_Y_B_node_string,
                    'ANOX_Y_BVV1',
                    '原烟气nox浓度'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.ANOX_Y_B,
                    'ANOX_Y_B',
                    infoList.ANOX_Y_B_node_string,
                    'ANOX_Y_BVV1'
                  )
                "
              >
                {{ "ANOX_Y_B" in infoList ? infoList.ANOX_Y_B : 0 }}&nbsp;mg/m³
              </div>
            </div>
            <div class="leftrow2">
              <div class="fl left-lable">1#尿素分配</div>
              <div class="fl left-lable leftjg">2#尿素分配</div>
              <div class="fl left-lable leftjg">3#尿素分配</div>
            </div>
            <div class="leftrow3">压缩空气</div>
            <div class="leftrow3-location">
              <div class="location1">炉内脱硝</div>
              <div
                class="location2"
                style="cursor: pointer"
                @click="
                  toIpt(
                    infoList.SO2LNSP,
                    'A01-KSP',
                    'SO2LNSP',
                    infoList.SO2LNSP_node_string
                  )
                "
              >
                {{ "SO2LNSP" in infoList ? infoList.SO2LNSP : 0 }}&nbsp;mg/m³
              </div>
              <div
                class="location3"
                style="cursor: pointer"
                @click="
                  toCompon(
                    2,
                    'ASO2_Y_B',
                    infoList.ASO2_Y_B_node_string,
                    'ASO2_Y_BVV1',
                    '原烟气SO2含量'
                  )
                "
                @dblclick="
                  Cclick(
                    infoList.ASO2_Y_B,
                    'ASO2_Y_B',
                    infoList.ASO2_Y_B_node_string,
                    'ASO2_Y_BVV1'
                  )
                "
              >
                {{ "ASO2_Y_B" in infoList ? infoList.ASO2_Y_B : 0 }}&nbsp;mg/m³
              </div>
              <div class="location4">炉膛</div>
            </div>
            <div class="leftrow4">
              <div class="fl">
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      infoList.QK0E && !infoList.QK0E.TS ? '#2AFC30' : 'red',
                  }"
                  @click="
                   infoList.QK0E
                      ? toDetail(2, 'QK0E', infoList.QK0E_node_string, '', 'QK0E设定值')
                      : ''
                  "
                >
                  K
                </div>
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      infoList.XK0H && !infoList.XK0H.RM ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.XK0H
                      ? toDetail(
                          1,
                          'XK0H',
                          infoList.XK0H_node_string,
                          '',
                          '氨水浓度调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl"
                  style="margin-left: 0.01vw"
                  :style="{
                    background:
                      infoList.MAN38.RM == 1 || infoList.MAN39.RM == 1
                        ? '#2AFC30'
                        : 'red',
                  }"
                  @click="toCompon(0, 'CFB_MANTX', 2)"
                >
                  A
                </div>
              </div>
              <div class="fl">
                <div
                  class="down leftrow4jg"
                  @click="
                    toIpt(
                      infoList.NOXSP,
                      '',
                      'NOXSP',
                      infoList.NOXSP_node_string
                    )
                  "
                >
                  {{ "NOXSP" in infoList ? infoList.NOXSP : 0 }}
                </div>
                <div
                  class="leftrow4jg1"
                  style="cursor: pointer"
                  @click="
                    toCompon(
                      2,
                      'NOXSPSJ',
                      infoList.NOXSPSJ_node_string,
                      'NOXSPSJVV1',
                      'NOX实际设定值'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.NOXSPSJ,
                      'NOXSPSJ',
                      infoList.NOXSPSJ_node_string,
                      'NOXSPSJVV1'
                    )
                  "
                >
                  {{ "NOXSPSJ" in infoList ? infoList.NOXSPSJ : 0 }}
                </div>
              </div>
              <div class="fl leftrow4jg2">
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      infoList.F_CYS_B && !infoList.XK0H ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.F_CYS_B
                      ? toDetail(
                          1,
                          'XK0H',
                          infoList.XK0H_node_string,
                          '',
                          '氨水浓度调节'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div
                  class="bottonx fl"
                  style="margin-left: 0.01vw"
                  :style="{
                    background:
                      infoList.MAN38.RM == 1 || infoList.MAN39.RM == 1
                        ? '#2AFC30'
                        : 'red',
                  }"
                  @click="toCompon(0, 'CFB_MANTX', 2)"
                >
                  A
                </div>
              </div>
              <div class="leftrow4jg3 flex">
                <div class="bottonx fl">K</div>
                <div
                  class="bottonx fl"
                  :style="{
                    background:
                      infoList.XK0T && !infoList.XK0T.RM ? '#2AFC30' : 'red',
                  }"
                  @click="
                    infoList.XK0T
                      ? toDetail(
                          1,
                          'XK0T',
                          infoList.XK0T_node_string,
                          '',
                          '氧含量调节先控'
                        )
                      : ''
                  "
                >
                  X
                </div>
                <div class="bottonx fl">A</div>
              </div>
            </div>
            <div class="leftrow5">
              <div class="fl">
                <div class="leftrow5wd">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'F_NSRY_B',
                        infoList.F_NSRY_B_node_string,
                        'F_NSRY_BVV1',
                        '尿素溶液流量'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.F_NSRY_B,
                        'F_NSRY_B',
                        infoList.F_NSRY_B_node_string,
                        'F_NSRY_BVV1'
                      )
                    "
                  >
                    {{
                      "F_NSRY_B" in infoList ? infoList.F_NSRY_B : 0
                    }}&nbsp;t/h
                  </div>
                </div>
                <div class="leftrow5wd">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'KF_TX_B',
                        infoList.KF_TX_B_node_string,
                        'KF_TX_BVV1',
                        '炉内SNCR脱硝脱硝剂控制阀门反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_TX_B,
                        'KF_TX_B',
                        infoList.KF_TX_B_node_string,
                        'KF_TX_BVV1'
                      )
                    "
                  >
                    {{ "KF_TX_B" in infoList ? infoList.KF_TX_B : 0 }}&nbsp;％
                  </div>
                </div>
              </div>
              <div class="fl leftrow5jg">
                <div class="leftrow5wd">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'F_CYS_B',
                        infoList.F_CYS_B_node_string,
                        'F_CYS_BVV1',
                        '化水除盐水流量'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.F_CYS_B,
                        'F_CYS_B',
                        infoList.F_CYS_B_node_string,
                        'F_CYS_BVV1'
                      )
                    "
                  >
                    {{ "F_CYS_B" in infoList ? infoList.F_CYS_B : 0 }}&nbsp;t/h
                  </div>
                </div>
                <div class="leftrow5wd">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'KF_CYS_B',
                        infoList.KF_CYS_B_node_string,
                        'KF_CYS_BVV1',
                        '除盐水阀位反馈'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.KF_CYS_B,
                        'KF_CYS_B',
                        infoList.KF_CYS_B_node_string,
                        'KF_CYS_BVV1'
                      )
                    "
                  >
                    {{ "KF_CYS_B" in infoList ? infoList.KF_CYS_B : 0 }}&nbsp;％
                  </div>
                </div>
              </div>
            </div>
            <div class="leftrow5">
              <div class="fl leftrow5jg3">尿素溶液</div>
              <div class="fl leftrow5jg2">化水除盐水</div>
              <div class="fl leftrow5jg4">石灰石粉</div>
            </div>
          </div>
          <!-- 中间部分 -->
          <div>
            <div class="centermain">
              <div class="cen-top">
                <div class="fl centerrow1">
                  <div class="cen-co1">原烟气监测</div>
                  <div class="cen-co2">
                    <div class="cen-co2-row1 fl">SO₂浓度</div>
                    <div class="cen-co2-row2 fl">
                      <div
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'ASO2_B',
                            infoList.ASO2_B_node_string,
                            'ASO2_Y_BVV1',
                            '原烟气SO2含量'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.ASO2_B,
                            'ASO2_B',
                            infoList.ASO2_B_node_string,
                            'ASO2_Y_BVV1'
                          )
                        "
                      >
                        {{
                          "ASO2_B" in infoList ? infoList.ASO2_B : 0
                        }}&nbsp;mg/m³
                      </div>
                    </div>
                    <div class="leftrow5wd"></div>
                  </div>
                  <div class="cen-co2">
                    <div class="cen-co2-row1 fl">NOx浓度</div>
                    <div class="cen-co2-row2 fl">
                      <div
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'ANOX_Y_B',
                            infoList.ANOX_Y_B_node_string,
                            'ANOX_Y_BVV1',
                            '原烟气nox浓度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.ANOX_Y_B,
                            'ANOX_Y_B',
                            infoList.ANOX_Y_B_node_string,
                            'ANOX_Y_BVV1'
                          )
                        "
                      >
                        {{
                          "ANOX_Y_B" in infoList ? infoList.ANOX_Y_B : 0
                        }}&nbsp;mg/m³
                      </div>
                    </div>
                    <div class="leftrow5wd"></div>
                  </div>
                  <div class="cen-co2">
                    <div class="cen-co2-row1 fl">O₂浓度</div>
                    <div class="cen-co2-row2 fl">
                      <div
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'O2_Y_B',
                            infoList.O2_Y_B_node_string,
                            'O2_Y_BVV1',
                            '原烟气含氧量'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.O2_Y_B,
                            'O2_Y_B',
                            infoList.O2_Y_B_node_string,
                            'O2_Y_BVV1'
                          )
                        "
                      >
                        {{ "O2_Y_B" in infoList ? infoList.O2_Y_B : 0 }}&nbsp;%
                      </div>
                    </div>
                    <div class="leftrow5wd"></div>
                  </div>
                  <div class="cen-co2">
                    <div class="cen-co2-row1 fl">DUST浓度</div>
                    <div class="cen-co2-row2 fl">
                      <div
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'DUST_Y_B',
                            infoList.DUST_Y_B_node_string,
                            'DUST_Y_BVV1',
                            '原烟气含尘量'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.DUST_Y_B,
                            'DUST_Y_B',
                            infoList.DUST_Y_B_node_string,
                            'DUST_Y_BVV1'
                          )
                        "
                      >
                        {{
                          "DUST_Y_B" in infoList ? infoList.DUST_Y_B : 0
                        }}&nbsp;mg/m³
                      </div>
                      <div class="leftrow5wd"></div>
                    </div>
                  </div>
                  <div class="cen-co2">
                    <div class="cen-co2-row1 fl">烟气温度</div>
                    <div class="cen-co2-row2 fl">
                      <div
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'TYQ_Y_B',
                            infoList.TYQ_Y_B_node_string,
                            'TYQ_Y_BVV1',
                            '原烟气烟气温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.TYQ_Y_B,
                            'TYQ_Y_B',
                            infoList.TYQ_Y_B_node_string,
                            'TYQ_Y_BVV1'
                          )
                        "
                      >
                        {{
                          "TYQ_Y_B" in infoList ? infoList.TYQ_Y_B : 0
                        }}&nbsp;℃
                      </div>
                      <div class="leftrow5wd"></div>
                    </div>
                  </div>
                </div>
                <div class="fl centerrow1">
                  <div class="cen-co1">净烟气监测</div>
                  <div class="cen-co2">
                    <div class="cen-co2-row1 fl">SO₂浓度</div>
                    <div class="cen-co2-row2 fl">
                      <div
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'ASO2_B',
                            infoList.ASO2_B_node_string,
                            'ASO2_BVV1',
                            '净烟气SO2浓度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.ASO2_B,
                            'ASO2_B',
                            infoList.ASO2_B_node_string,
                            'ASO2_BVV1'
                          )
                        "
                      >
                        {{
                          "ASO2_B" in infoList ? infoList.ASO2_B : 0
                        }}&nbsp;mg/m³
                      </div>
                      <div class="leftrow5wd"></div>
                    </div>
                  </div>
                  <div class="cen-co2">
                    <div class="cen-co2-row1 fl">NOx浓度</div>
                    <div class="cen-co2-row2 fl">
                      <div
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'ANOX_B',
                            infoList.ANOX_B_node_string,
                            'ANOX_BVV1',
                            '净烟气NOX浓度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.ANOX_B,
                            'ANOX_B',
                            infoList.ANOX_B_node_string,
                            'ANOX_BVV1'
                          )
                        "
                      >
                        {{
                          "ANOX_B" in infoList ? infoList.ANOX_B : 0
                        }}&nbsp;mg/m³
                      </div>
                      <div class="leftrow5wd"></div>
                    </div>
                  </div>
                  <div class="cen-co2">
                    <div class="cen-co2-row1 fl">O₂浓度</div>
                    <div class="cen-co2-row2 fl">
                      <div
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'ASO2_B',
                            infoList.ASO2_B_node_string,
                            'O2_BVV1',
                            '净烟气含氧量'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.ASO2_B,
                            'ASO2_B',
                            infoList.ASO2_B_node_string,
                            'O2_BVV1'
                          )
                        "
                      >
                        {{ "ASO2_B" in infoList ? infoList.ASO2_B : 0 }}&nbsp;%
                      </div>
                      <div class="leftrow5wd"></div>
                    </div>
                  </div>
                  <div class="cen-co2">
                    <div class="cen-co2-row1 fl">DUST浓度</div>
                    <div class="cen-co2-row2 fl">
                      <div
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'DUST_B',
                            infoList.DUST_B_node_string,
                            'DUST_BVV1',
                            '净烟气含尘量'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.DUST_B,
                            'DUST_B',
                            infoList.DUST_B_node_string,
                            'DUST_BVV1'
                          )
                        "
                      >
                        {{
                          "DUST_B" in infoList ? infoList.DUST_B : 0
                        }}&nbsp;mg/m³
                      </div>
                      <div class="leftrow5wd"></div>
                    </div>
                  </div>
                  <div class="cen-co2">
                    <div class="cen-co2-row1 fl">烟气温度</div>
                    <div class="cen-co2-row2 fl">
                      <div
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'T_YQ_B',
                            infoList.T_YQ_B_node_string,
                            'T_YQ_BVV1',
                            '净烟气烟气温度'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.T_YQ_B,
                            'T_YQ_B',
                            infoList.T_YQ_B_node_string,
                            'T_YQ_BVV1'
                          )
                        "
                      >
                        {{ "T_YQ_B" in infoList ? infoList.T_YQ_B : 0 }}&nbsp;℃
                      </div>
                      <div class="leftrow5wd"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cen-lable">工艺水</div>
              <div class="cen-lable1">原烟气</div>
              <div class="cen-lable2">
                <div
                  style="cursor: pointer"
                  @click="
                    toCompon(
                      2,
                      'TYQ_Y_B',
                      infoList.TYQ_Y_B_node_string,
                      'TYQ_Y_BVV1',
                      '原烟气烟气温度'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.TYQ_Y_B,
                      'TYQ_Y_B',
                      infoList.TYQ_Y_B_node_string,
                      'TYQ_Y_BVV1'
                    )
                  "
                >
                  {{ "TYQ_Y_B" in infoList ? infoList.TYQ_Y_B : 0 }}&nbsp;℃
                </div>
              </div>
              <div class="cen-lable3">循环泵A</div>
              <div class="cen-lable3">循环泵B</div>
              <div class="cen-lable3">循环泵C</div>
              <div class="cen-lable3">循环泵D</div>
            </div>
          </div>

          <div class="rightmain flex">
            <div class="rig-left">
              <div class="rig-lable1">脱硫塔</div>
              <div class="rig-lable1 rig-jge flex">
                <div class="rig-row1">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'T_XST1_B',
                        infoList.T_XST1_B_node_string,
                        'T_XST1_BVV1',
                        '吸收塔温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.T_XST1_B,
                        'T_XST1_B',
                        infoList.T_XST1_B_node_stringCEinfoList
                          .T_XST1_B_node_string,
                        'T_XST1_BVV1'
                      )
                    "
                  >
                    {{ "T_XST1_B" in infoList ? infoList.T_XST1_B : 0 }}&nbsp;℃
                  </div>
                </div>
                <div class="rig-row1">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'T_XST2_B',
                        infoList.T_XST2_B_node_string,
                        'T_XST2_BVV1',
                        '吸收塔温度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.T_XST2_B,
                        'T_XST2_B',
                        infoList.T_XST2_B_node_string,
                        'T_XST2_BVV1'
                      )
                    "
                  >
                    {{ "T_XST2_B" in infoList ? infoList.T_XST2_B : 0 }}&nbsp;℃
                  </div>
                </div>
              </div>
              <div class="rig-lable1 rig-jge1 flex">
                <div class="rig-row1">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'LTJYC1_B',
                        infoList.LTJYC1_B_node_string,
                        'LTJYC1_BVV1',
                        '吸收塔浆液池液位'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LTJYC1_B,
                        'LTJYC1_B',
                        infoList.LTJYC1_B_node_string,
                        'LTJYC1_BVV1'
                      )
                    "
                  >
                    {{ "LTJYC1_B" in infoList ? infoList.LTJYC1_B : 0 }}&nbsp;m
                  </div>
                </div>
                <div class="rig-row1">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'LTJYC2_B',
                        infoList.LTJYC2_B_node_string,
                        'LTJYC2_BVV1',
                        '吸收塔浆液池液位'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.LTJYC2_B,
                        'LTJYC2_B',
                        infoList.LTJYC2_B_node_string,
                        'LTJYC2_BVV1'
                      )
                    "
                  >
                    {{ "LTJYC2_B" in infoList ? infoList.LTJYC2_B : 0 }}&nbsp;m
                  </div>
                </div>
              </div>
              <div class="rig-lable1 rig-jge2 flex" style="margin-left: 0.5vw">
                <div class="rig-row1">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'DTJYC1_B',
                        infoList.DTJYC1_B_node_string,
                        'DTJYC1_BVV1',
                        '吸收塔浆液池密度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DTJYC1_B,
                        'DTJYC1_B',
                        infoList.DTJYC1_B_node_string,
                        'DTJYC1_BVV1'
                      )
                    "
                  >
                    {{
                      "DTJYC1_B" in infoList ? infoList.DTJYC1_B : 0
                    }}&nbsp;g/m3
                  </div>
                </div>
                <div class="rig-row1">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'DTJYC2_B',
                        infoList.DTJYC2_B_node_string,
                        'DTJYC2_BVV1',
                        '吸收塔浆液池密度'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.DTJYC2_B,
                        'DTJYC2_B',
                        infoList.DTJYC2_B_node_string,
                        'DTJYC2_BVV1'
                      )
                    "
                  >
                    {{
                      "DTJYC2_B" in infoList ? infoList.DTJYC2_B : 0
                    }}&nbsp;g/m3
                  </div>
                </div>
              </div>
              <div class="rig-lable1 rig-jge2 flex">
                <div class="rig-row1">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'PH1_B',
                        infoList.PH1_B_node_string,
                        'PH1_BVV1',
                        '吸收塔浆液池PH值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.PH1_B,
                        'PH1_B',
                        infoList.PH1_B_node_string,
                        'PH1_BVV1'
                      )
                    "
                  >
                    {{ "" in infoList ? infoList.PH1_B : 0 }}&nbsp;ph
                  </div>
                </div>
                <div class="rig-row1">
                  <div
                    style="cursor: pointer"
                    @click="
                      toCompon(
                        2,
                        'PH2_B',
                        'MCSSOURCE',
                        'PH2_BVV1',
                        '吸收塔浆液池PH值'
                      )
                    "
                    @dblclick="
                      Cclick(
                        infoList.MCSSOURCE.PH2_B,
                        'PH2_B',
                        'MCSSOURCE',
                        'PH2_BVV1'
                      )
                    "
                  >
                    {{ "" in infoList ? infoList.MCSSOURCE.PH2_B : 0 }}&nbsp;ph
                  </div>
                </div>
              </div>
              <div class="rig-lable1 rig-jge2 flex">
                <div class="rig-row1">
                  <div
                    class="down"
                    style="cursor: pointer"
                    @click="
                      toIpt(infoList.MCSO2NOX.PH_H, '', 'PH_H', 'MCSO2NOX')
                    "
                  >
                    {{ "" in infoList ? infoList.MCSO2NOX.PH_H : 0 }}
                  </div>
                </div>
                <div class="rig-row1">
                  <div
                    class="down"
                    style="cursor: pointer"
                    @click="
                      toIpt(infoList.MCSO2NOX.PH_L, '', 'PH_L', 'MCSO2NOX')
                    "
                  >
                    {{ "" in infoList ? infoList.MCSO2NOX.PH_L : 0 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="rig-right">
              <div class="rig-lable1">
                <div
                  class="rig-rightjg"
                  style="cursor: pointer"
                  @click="
                    toCompon(
                      2,
                      'T_YQ_B',
                      infoList.T_YQ_B_node_string,
                      'T_YQ_BVV1',
                      '净烟气烟气温度'
                    )
                  "
                  @dblclick="
                    Cclick(
                      infoList.T_YQ_B,
                      'T_YQ_B',
                      infoList.T_YQ_B_node_string,
                      'T_YQ_BVV1'
                    )
                  "
                >
                  {{ "T_YQ_B" in infoList ? infoList.T_YQ_B : 0 }}&nbsp;℃
                </div>
              </div>
              <div class="righ-lable2">除雾器冲洗水</div>
              <div class="righ-lable3">
                <div class="flex">
                  <div>
                    <div class="righ-col1 flex">
                      <div
                        class="righ-col1-lb down"
                        style="cursor: pointer"
                        @click="
                          toIpt(
                            infoList.SO2SP,
                            '',
                            'SO2SP',
                            infoList.SO2SP_node_string
                          )
                        "
                      >
                        {{ "SO2SP" in infoList ? infoList.SO2SP : 0 }}
                      </div>
                      <div
                        class="righ-col1-lb"
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'SO2SPSJ',
                            infoList.SO2SPSJ_node_string,
                            'SO2SPSJVV1',
                            '炉外so2实际设定值'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.SO2SPSJ,
                            'SO2SPSJ',
                            infoList.SO2SPSJ_node_string,
                            'SO2SPSJVV1'
                          )
                        "
                      >
                        {{
                          "SO2SPSJ" in infoList ? infoList.SO2SPSJ : 0
                        }}&nbsp;℃
                      </div>
                    </div>
                    <div class="righ-col1 righ-col1-jg flex">
                      <div
                        class="righ-col1-lb"
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'F_SHJY_B',
                            infoList.F_SHJY_B_node_string,
                            'F_SHJY_BVV1',
                            '石灰浆液流量'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.F_SHJY_B,
                            'F_SHJY_B',
                            infoList.F_SHJY_B_node_string,
                            'F_SHJY_BVV1'
                          )
                        "
                      >
                        {{
                          "F_SHJY_B" in infoList ? infoList.F_SHJY_B : 0
                        }}&nbsp;m3/h
                      </div>
                      <div
                        class="righ-col1-lb righ-col1-jg2"
                        style="cursor: pointer"
                        @click="
                          toCompon(
                            2,
                            'KF_TL_B',
                            infoList.KF_TL_B_node_string,
                            'KF_TL_BVV1',
                            '脱硫石灰浆液流量控制阀门反馈'
                          )
                        "
                        @dblclick="
                          Cclick(
                            infoList.KF_TL_B,
                            'KF_TL_B',
                            infoList.KF_TL_B_node_string,
                            'KF_TL_BVV1'
                          )
                        "
                      >
                        {{
                          "KF_TL_B" in infoList ? infoList.KF_TL_B : 0
                        }}&nbsp;%
                      </div>
                    </div>
                    <div class="righ-col1-jg3 flex">
                      <div
                        class="bottonx jgxx"
                        :style="{
                          background:
                            infoList.QK0F && !infoList.QK0F.TS
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.QK0F
                            ? toDetail(
                                2,
                                'QK0F',
                                infoList.QK0F_node_string,
                                '',
                                'QK0F设定值'
                              )
                            : ''
                        "
                      >
                        K
                      </div>
                      <div
                        class="bottonx"
                        :style="{
                          background:
                            infoList.XK0R && !infoList.XK0R.RM
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.XK0R
                            ? toDetail(
                                1,
                                'XK0R',
                                'XK0R',
                                infoList.XK0R_node_string,
                                '氧含量调节先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="bottonx"
                        :style="{
                          background:
                            infoList.XK0S && !infoList.XK0S.RM
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.XK0S
                            ? toDetail(
                                1,
                                'XK0S',
                                infoList.XK0S_node_string,
                                '',
                                '氧含量调节先控'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="bottonx"
                        :style="{
                          background:
                            infoList.XK0F && !infoList.XK0F.RM
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="
                          infoList.XK0F
                            ? toDetail(
                                1,
                                'XK0F',
                                infoList.XK0F_node_string,
                                '',
                                '脱硫石灰石调节'
                              )
                            : ''
                        "
                      >
                        X
                      </div>
                      <div
                        class="bottonx"
                        :style="{
                          background:
                            infoList.MAN37.RM == 1 || infoList.MAN40.RM == 1
                              ? '#2AFC30'
                              : 'red',
                        }"
                        @click="toCompon(0, 'CFB_MANTL', 2)"
                      >
                        A
                      </div>
                    </div>
                  </div>
                  <div class="jange">
                    <div class="mag-top1">石灰石旋流站</div>
                    <div class="mag-top2">事故浆液罐</div>
                    <div class="mag-top3">集水坑</div>
                    <div class="mag-top1">石灰石浆液</div>
                    <div class="mag-top4">氧化空气</div>
                  </div>
                </div>
                <div class="righ-lable4 flex">
                  <div>
                    <div class="jianh">石膏排除泵A</div>
                    <div class="jianh1">石膏排除泵B</div>
                  </div>
                  <div class="jianh3">
                    <div class="">工艺水</div>
                    <div class="jianh4">事故浆液罐</div>
                    <div class="jianh4">石膏旋流站</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <inputVal ref="inputVal"></inputVal>
    <Historical
      v-if="isHshow"
      @sendStatus="isHshow = false"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Cfbcysz
      v-if="flag == 5 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Cfbcysz>
    <Cfbserve
      v-if="flag == 7 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></Cfbserve>
    <manyManual
      v-if="manyManual"
      :titname="Manualtitname"
      :numM="Manualnode"
      @sendStatus="isClose"
      :infoList="infoList"
    ></manyManual>
  </div>
</template>
				
<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import manyManual from "@/components/manyManual1.vue"; //手操器组件
import Cfbcysz from "@/views/CfbBoiler/CfbCommonSetting/index.vue"; //常用设置组件
import Cfbserve from "@/views/CfbBoiler/CfbOnlineService/index.vue"; //客服组件

export default {
  name: "CfbDesulphurization",
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  components: { inputVal, Historical, manyManual, Cfbcysz, Cfbserve },
  data: () => {
    return {
      chName: "",
      manyManual: false,
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
      flag: 1,
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
        console.log(this.infoList);
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.$bus.$on("sendMsg", (msg) => {
      // 兄弟组件发送来的消息
      this.flag = msg;
    });
  },
  methods: {
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "Cfbkfirstcontol";
            break;
          case 3:
            pathname = "CfbRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }
        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "Param041", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "manyManual":
          return (this.manyManual = val1);
        case "Historical":
          return (this.isHshow = val1);
        case "CfbCommonSetting":
        case this.flag == 5:
          return (this.flag = 1), this.$bus.$emit("footersendMsg", 1);
        case "Cfbserve":
        case this.flag == 7:
          return (this.flag = 1), this.$bus.$emit("footersendMsg", 1);
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            return (
              (this.manyManual = true),
              (this.Manualtitname = name),
              (this.Manualnode = name2)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4);
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
        }
      }, 300);
    },
    // 打开趋势组图页面
    qsGroup() {
      this.$router.push({ path: "/trendGroup" });
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>
			
	<style lang="scss" scoped>
.rqltltx {
  // margin-top:4vh;
  width: 99vw;
  height: 100%;
  margin-left: 1vw;
  font-size: 1.6vh;
  font-family: PingFang-SC-Regular;
  color: #fff;

  .fl {
    float: left;
  }

  .bgmain {
    margin-top: 0vh;
    width: 100vw;
    height: 92vh;
    // background-image: url("~@/assets/images/CfbBoiler/rql_tltx.png");
    // background-size: 100% 100%;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .title {
    width: 100%;
    height: 4vh;
    text-align: center;
    margin-top: 2vh;
    margin-bottom: 14vh;
    height: 4vh;
    font-family: MicrosoftYaHei;
    font-size: 1.6vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vw;
    color: #ffffff;
  }

  .row1-main {
    width: 96vw;
    height: 78vh;
    // border: #ffffff 1px solid;
    margin-top: -14vh;
    background-image: url("~@/assets/images/CfbBoiler/rql_tltx.png");
    background-size: 100% 100%;
  }

  .leftmain {
    width: 28vw;
    height: 78vh;
    margin-top: 3vh;
    // border:1px solid springgreen
  }

  .centermain {
    width: 35vw;
    height: 78vh;
    margin-top: 2vh;
    // border:1px solid springgreen
  }

  .leftrow1 {
    width: 10vw;
    margin-left: 12vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.2vw;
    color: #fefefe;
  }

  .leftrow1-right {
    margin-left: -1vw;
    margin-top: 1vh;
  }

  .leftrow2 {
    width: 28vw;
    margin-top: 16vh;
    margin-left: 1vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.2vw;
    color: #fefefe;
  }

  .left-lable {
    margin-top: 5vh;
    width: 6vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: red;
  }

  .leftjg {
    margin-left: 4vw;
  }

  .leftrow3 {
    width: 1vw;
    margin-top: 20vh;
    margin-left: 2.5vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.2vw;
    letter-spacing: 0.1vw;
    color: #5ca4a6;
  }
  .leftrow3-location {
    width: 11vw;
    height: 14vh;
    font-size: 1vw;
    line-height: 1.2vw;
    margin-left: 22vw;
    margin-top: 2vh;
    // .location1{

    // }
    .location2 {
      margin-top: 1.5vh;
      margin-left: 3vw;
      color: #1e8df3;
    }
    .location3 {
      margin-left: 3vw;
    }
    .location4 {
      margin-top: 1vh;
      text-align: center;
    }
  }
  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 0.9vw;
    margin-right: 0.1vw;
    color: white;
    background-color: red;
    position: relative;
    z-index: 9;
    cursor: pointer;
  }

  .leftrow4 {
    margin-top: 0vh;
    width: 28vw;
    margin-left: 4.5vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.1vw;
    color: #5ca4a6;
  }

  .down {
    color: #14a4ff;
  }

  .leftrow4jg {
    margin-left: 3.2vw;
    height: 3vh;
    margin-top: -4vh;
  }

  .leftrow4jg1 {
    margin-left: 3.2vw;
    height: 3vh;
    color: #fff;
  }

  .leftrow4jg2 {
    margin-left: 3.5vw;
    height: 3vh;
    color: #fff;
  }
  .leftrow4jg3 {
    margin-left: 23.5vw;
    height: 3vh;
    color: #fff;
  }

  .leftrow5 {
    margin-top: 1.5vh;
    width: 28vw;
    margin-left: 4vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0.1vw;
    color: #fff;
  }

  .leftrow5wd {
    width: 10vw;
    height: 3vh;
  }

  .leftrow5jg {
    margin-left: 4vw;
  }

  .leftrow5jg2 {
    margin-left: 8vw;
    margin-top: 2vh;
    color: #5ca4a6;
  }

  .leftrow5jg3 {
    margin-top: 2vh;
    margin-left: 0.8vw;
    color: #5ca4a6;
  }

  .leftrow5jg4 {
    margin-top: 2vh;
    margin-left: 4.8vw;
    color: #5ca4a6;
  }

  .cen-top {
    width: 35vw;
    height: 25.8vh;
  }

  .cen-lable {
    margin-top: 6vh;
    margin-left: 6.5vw;
    width: 1vw;
    height: 7vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2.2vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
  }

  .cen-lable1 {
    margin-top: 4vh;
    margin-left: 25vw;
    widows: 1vw;
    height: 7vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #fff;
  }

  .cen-lable2 {
    margin-top: -1vh;
    margin-left: 25vw;
    widows: 1vw;
    height: 7vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #fff;
  }

  .cen-lable3 {
    margin-top: -1vh;
    margin-left: 25vw;
    width: 5vw;
    height: 7vh;
    font-family: PingFang-SC-Regular;
    font-size: 1.1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
  }

  .centerrow1 {
    width: 15.5vw;
    height: 25.8vh;
    border-top: 1px solid #0e3140;
    border-left: 1px solid #0e3140;
    border-right: 1px solid #0e3140;
    margin-left: 1vw;
  }

  .cen-co1 {
    width: 15.5vw;
    height: 5vh;
    background-color: #205365;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    font-family: MicrosoftYaHei;
    line-height: 5vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
    text-align: center;
  }

  .cen-co2 {
    width: 15.5vw;
    height: 4vh;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    color: #5ca4a6;
    text-align: center;
    border-bottom: 1px solid #0e3140;
  }

  .cen-co2-row1 {
    width: 7.6vw;
    height: 4vh;
    color: #53d0d3;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    text-align: center;
    font-family: PingFang-SC-Regular;
  }

  .jange {
    margin-left: 0vw;
    color: #5ca4a6;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
  }

  .mag-top1 {
    width: 7vw;
    margin-top: 1.8vh;
    margin-left: 1vw;
  }

  .mag-top2 {
    width: 7vw;
    margin-top: 1.8vh;
    margin-left: 1vw;
  }

  .mag-top3 {
    width: 7vw;
    margin-top: 1.3vh;
    margin-left: 1vw;
  }

  .mag-top4 {
    width: 7vw;
    margin-top: 3vh;
    margin-left: 1vw;
  }

  .cen-co2-row2 {
    width: 7.6vw;
    height: 4vh;
    color: #fff;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    text-align: center;
    font-family: PingFang-SC-Regular;
    border-left: 1px solid #0e3140;
  }

  .rightmain {
    margin-top: 2.7vh;
    margin-left: -1vw;
  }

  .rig-left {
    width: 14vw;
    height: 80vh;
    // border:solid springgreen 1px
  }

  .rig-lable1 {
    // width: 14vw;
    font-family: PingFang-SC-Regular;
    font-size: 1.5vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vw;
    color: #fefefe;
    text-align: center;
    // background-color: red;
  }

  .rig-row1 {
    width: 5vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vw;
    letter-spacing: 0vw;
    color: #fefefe;
    text-align: center;
    margin-left: 1.3vw;
    // background-color: purple;
  }

  .rig-jge {
    margin-top: 40vh;
  }

  .rig-jge1 {
    margin-top: 5vh;
  }

  .rig-jge2 {
    margin-top: 3vh;
  }

  .rig-right {
    width: 20vw;
    height: 80vh;
    // border:solid springgreen 1px
  }

  .rig-rightjg {
    margin-left: 6vw;
  }

  .righ-lable2 {
    margin-top: 1.5vh;
    margin-left: 17vw;
    width: 7vw;
    height: 2vh;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vw;
    color: #5ca4a6;
    // border:1px salmon solid;
  }
  .righ-lable3 {
    margin-top: 26vh;
    margin-left: 2vw;
    // width: 21vw;
    // height: 20vh;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1vw;
    letter-spacing: 0vw;
  }

  .righ-lable4 {
    margin-top: 1.6vh;
    margin-left: 2vw;
    font-family: MicrosoftYaHei;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1vw;
    letter-spacing: 0vw;
    color: #5ca4a6;
    // border:1px salmon solid
  }

  .righ-col1 {
    width: 12vw;
    height: 20vh;
    margin-top: 3.5vh;
    font-size: 1vw;
    margin-left: 1vw;
  }

  .righ-col1-lb {
    width: 5vw;
    height: 2vh;
    margin-left: 0.5vw;
  }

  .righ-col1-jg {
    margin-top: -8vh;
  }

  .righ-col1-jg3 {
    margin-top: -15.5vh;
    margin-left: 3.5vw;
  }

  .jgxx {
    margin-left: 1vw;
    margin-top: vh;
  }

  .righ-col1-jg2 {
    margin-left: 1vw;
  }

  .jianh {
    width: 6vw;
    margin-top: 0vh;
    margin-left: -1vw;
  }

  .jianh1 {
    width: 6vw;
    margin-top: 14vh;
    margin-left: -1vw;
  }

  .jianh3 {
    margin-left: 7vw;
    // margin-top: -21vh;
  }

  .jianh4 {
    width: 6vw;
    margin-top: 4.5vh;
  }
}
</style>
