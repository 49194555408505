var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"rqltltx"},[_c('div',{staticClass:"bgmain"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"脱硫脱硝系统")]),_c('div',{staticClass:"row1-main flex"},[_c('div',{staticClass:"leftmain"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"leftrow1"},[_vm._v("炉膛口")]),_c('div',{staticClass:"leftrow1-right",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ANOX_Y_B',
                  _vm.infoList.ANOX_Y_B_node_string,
                  'ANOX_Y_BVV1',
                  '原烟气nox浓度'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ANOX_Y_B,
                  'ANOX_Y_B',
                  _vm.infoList.ANOX_Y_B_node_string,
                  'ANOX_Y_BVV1'
                )}}},[_vm._v(" "+_vm._s("ANOX_Y_B" in _vm.infoList ? _vm.infoList.ANOX_Y_B : 0)+" mg/m³ ")])]),_vm._m(0),_c('div',{staticClass:"leftrow3"},[_vm._v("压缩空气")]),_c('div',{staticClass:"leftrow3-location"},[_c('div',{staticClass:"location1"},[_vm._v("炉内脱硝")]),_c('div',{staticClass:"location2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.SO2LNSP,
                  'A01-KSP',
                  'SO2LNSP',
                  _vm.infoList.SO2LNSP_node_string
                )}}},[_vm._v(" "+_vm._s("SO2LNSP" in _vm.infoList ? _vm.infoList.SO2LNSP : 0)+" mg/m³ ")]),_c('div',{staticClass:"location3",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ASO2_Y_B',
                  _vm.infoList.ASO2_Y_B_node_string,
                  'ASO2_Y_BVV1',
                  '原烟气SO2含量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ASO2_Y_B,
                  'ASO2_Y_B',
                  _vm.infoList.ASO2_Y_B_node_string,
                  'ASO2_Y_BVV1'
                )}}},[_vm._v(" "+_vm._s("ASO2_Y_B" in _vm.infoList ? _vm.infoList.ASO2_Y_B : 0)+" mg/m³ ")]),_c('div',{staticClass:"location4"},[_vm._v("炉膛")])]),_c('div',{staticClass:"leftrow4"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"bottonx fl",style:({
                  background:
                    _vm.infoList.QK0E && !_vm.infoList.QK0E.TS ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.QK0E
                    ? _vm.toDetail(2, 'QK0E', _vm.infoList.QK0E_node_string, '', 'QK0E设定值')
                    : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"bottonx fl",style:({
                  background:
                    _vm.infoList.XK0H && !_vm.infoList.XK0H.RM ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.XK0H
                    ? _vm.toDetail(
                        1,
                        'XK0H',
                        _vm.infoList.XK0H_node_string,
                        '',
                        '氨水浓度调节'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",staticStyle:{"margin-left":"0.01vw"},style:({
                  background:
                    _vm.infoList.MAN38.RM == 1 || _vm.infoList.MAN39.RM == 1
                      ? '#2AFC30'
                      : 'red',
                }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANTX', 2)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"fl"},[_c('div',{staticClass:"down leftrow4jg",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.NOXSP,
                    '',
                    'NOXSP',
                    _vm.infoList.NOXSP_node_string
                  )}}},[_vm._v(" "+_vm._s("NOXSP" in _vm.infoList ? _vm.infoList.NOXSP : 0)+" ")]),_c('div',{staticClass:"leftrow4jg1",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                    2,
                    'NOXSPSJ',
                    _vm.infoList.NOXSPSJ_node_string,
                    'NOXSPSJVV1',
                    'NOX实际设定值'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.NOXSPSJ,
                    'NOXSPSJ',
                    _vm.infoList.NOXSPSJ_node_string,
                    'NOXSPSJVV1'
                  )}}},[_vm._v(" "+_vm._s("NOXSPSJ" in _vm.infoList ? _vm.infoList.NOXSPSJ : 0)+" ")])]),_c('div',{staticClass:"fl leftrow4jg2"},[_c('div',{staticClass:"bottonx fl",style:({
                  background:
                    _vm.infoList.F_CYS_B && !_vm.infoList.XK0H ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.F_CYS_B
                    ? _vm.toDetail(
                        1,
                        'XK0H',
                        _vm.infoList.XK0H_node_string,
                        '',
                        '氨水浓度调节'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl",staticStyle:{"margin-left":"0.01vw"},style:({
                  background:
                    _vm.infoList.MAN38.RM == 1 || _vm.infoList.MAN39.RM == 1
                      ? '#2AFC30'
                      : 'red',
                }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANTX', 2)}}},[_vm._v(" A ")])]),_c('div',{staticClass:"leftrow4jg3 flex"},[_c('div',{staticClass:"bottonx fl"},[_vm._v("K")]),_c('div',{staticClass:"bottonx fl",style:({
                  background:
                    _vm.infoList.XK0T && !_vm.infoList.XK0T.RM ? '#2AFC30' : 'red',
                }),on:{"click":function($event){_vm.infoList.XK0T
                    ? _vm.toDetail(
                        1,
                        'XK0T',
                        _vm.infoList.XK0T_node_string,
                        '',
                        '氧含量调节先控'
                      )
                    : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx fl"},[_vm._v("A")])])]),_c('div',{staticClass:"leftrow5"},[_c('div',{staticClass:"fl"},[_c('div',{staticClass:"leftrow5wd"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'F_NSRY_B',
                      _vm.infoList.F_NSRY_B_node_string,
                      'F_NSRY_BVV1',
                      '尿素溶液流量'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.F_NSRY_B,
                      'F_NSRY_B',
                      _vm.infoList.F_NSRY_B_node_string,
                      'F_NSRY_BVV1'
                    )}}},[_vm._v(" "+_vm._s("F_NSRY_B" in _vm.infoList ? _vm.infoList.F_NSRY_B : 0)+" t/h ")])]),_c('div',{staticClass:"leftrow5wd"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_TX_B',
                      _vm.infoList.KF_TX_B_node_string,
                      'KF_TX_BVV1',
                      '炉内SNCR脱硝脱硝剂控制阀门反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KF_TX_B,
                      'KF_TX_B',
                      _vm.infoList.KF_TX_B_node_string,
                      'KF_TX_BVV1'
                    )}}},[_vm._v(" "+_vm._s("KF_TX_B" in _vm.infoList ? _vm.infoList.KF_TX_B : 0)+" ％ ")])])]),_c('div',{staticClass:"fl leftrow5jg"},[_c('div',{staticClass:"leftrow5wd"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'F_CYS_B',
                      _vm.infoList.F_CYS_B_node_string,
                      'F_CYS_BVV1',
                      '化水除盐水流量'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.F_CYS_B,
                      'F_CYS_B',
                      _vm.infoList.F_CYS_B_node_string,
                      'F_CYS_BVV1'
                    )}}},[_vm._v(" "+_vm._s("F_CYS_B" in _vm.infoList ? _vm.infoList.F_CYS_B : 0)+" t/h ")])]),_c('div',{staticClass:"leftrow5wd"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'KF_CYS_B',
                      _vm.infoList.KF_CYS_B_node_string,
                      'KF_CYS_BVV1',
                      '除盐水阀位反馈'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.KF_CYS_B,
                      'KF_CYS_B',
                      _vm.infoList.KF_CYS_B_node_string,
                      'KF_CYS_BVV1'
                    )}}},[_vm._v(" "+_vm._s("KF_CYS_B" in _vm.infoList ? _vm.infoList.KF_CYS_B : 0)+" ％ ")])])])]),_vm._m(1)]),_c('div',[_c('div',{staticClass:"centermain"},[_c('div',{staticClass:"cen-top"},[_c('div',{staticClass:"fl centerrow1"},[_c('div',{staticClass:"cen-co1"},[_vm._v("原烟气监测")]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("SO₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'ASO2_B',
                          _vm.infoList.ASO2_B_node_string,
                          'ASO2_Y_BVV1',
                          '原烟气SO2含量'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.ASO2_B,
                          'ASO2_B',
                          _vm.infoList.ASO2_B_node_string,
                          'ASO2_Y_BVV1'
                        )}}},[_vm._v(" "+_vm._s("ASO2_B" in _vm.infoList ? _vm.infoList.ASO2_B : 0)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("NOx浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'ANOX_Y_B',
                          _vm.infoList.ANOX_Y_B_node_string,
                          'ANOX_Y_BVV1',
                          '原烟气nox浓度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.ANOX_Y_B,
                          'ANOX_Y_B',
                          _vm.infoList.ANOX_Y_B_node_string,
                          'ANOX_Y_BVV1'
                        )}}},[_vm._v(" "+_vm._s("ANOX_Y_B" in _vm.infoList ? _vm.infoList.ANOX_Y_B : 0)+" mg/m³ ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("O₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'O2_Y_B',
                          _vm.infoList.O2_Y_B_node_string,
                          'O2_Y_BVV1',
                          '原烟气含氧量'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.O2_Y_B,
                          'O2_Y_B',
                          _vm.infoList.O2_Y_B_node_string,
                          'O2_Y_BVV1'
                        )}}},[_vm._v(" "+_vm._s("O2_Y_B" in _vm.infoList ? _vm.infoList.O2_Y_B : 0)+" % ")])]),_c('div',{staticClass:"leftrow5wd"})]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("DUST浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'DUST_Y_B',
                          _vm.infoList.DUST_Y_B_node_string,
                          'DUST_Y_BVV1',
                          '原烟气含尘量'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.DUST_Y_B,
                          'DUST_Y_B',
                          _vm.infoList.DUST_Y_B_node_string,
                          'DUST_Y_BVV1'
                        )}}},[_vm._v(" "+_vm._s("DUST_Y_B" in _vm.infoList ? _vm.infoList.DUST_Y_B : 0)+" mg/m³ ")]),_c('div',{staticClass:"leftrow5wd"})])]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("烟气温度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'TYQ_Y_B',
                          _vm.infoList.TYQ_Y_B_node_string,
                          'TYQ_Y_BVV1',
                          '原烟气烟气温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.TYQ_Y_B,
                          'TYQ_Y_B',
                          _vm.infoList.TYQ_Y_B_node_string,
                          'TYQ_Y_BVV1'
                        )}}},[_vm._v(" "+_vm._s("TYQ_Y_B" in _vm.infoList ? _vm.infoList.TYQ_Y_B : 0)+" ℃ ")]),_c('div',{staticClass:"leftrow5wd"})])])]),_c('div',{staticClass:"fl centerrow1"},[_c('div',{staticClass:"cen-co1"},[_vm._v("净烟气监测")]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("SO₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'ASO2_B',
                          _vm.infoList.ASO2_B_node_string,
                          'ASO2_BVV1',
                          '净烟气SO2浓度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.ASO2_B,
                          'ASO2_B',
                          _vm.infoList.ASO2_B_node_string,
                          'ASO2_BVV1'
                        )}}},[_vm._v(" "+_vm._s("ASO2_B" in _vm.infoList ? _vm.infoList.ASO2_B : 0)+" mg/m³ ")]),_c('div',{staticClass:"leftrow5wd"})])]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("NOx浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'ANOX_B',
                          _vm.infoList.ANOX_B_node_string,
                          'ANOX_BVV1',
                          '净烟气NOX浓度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.ANOX_B,
                          'ANOX_B',
                          _vm.infoList.ANOX_B_node_string,
                          'ANOX_BVV1'
                        )}}},[_vm._v(" "+_vm._s("ANOX_B" in _vm.infoList ? _vm.infoList.ANOX_B : 0)+" mg/m³ ")]),_c('div',{staticClass:"leftrow5wd"})])]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("O₂浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'ASO2_B',
                          _vm.infoList.ASO2_B_node_string,
                          'O2_BVV1',
                          '净烟气含氧量'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.ASO2_B,
                          'ASO2_B',
                          _vm.infoList.ASO2_B_node_string,
                          'O2_BVV1'
                        )}}},[_vm._v(" "+_vm._s("ASO2_B" in _vm.infoList ? _vm.infoList.ASO2_B : 0)+" % ")]),_c('div',{staticClass:"leftrow5wd"})])]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("DUST浓度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'DUST_B',
                          _vm.infoList.DUST_B_node_string,
                          'DUST_BVV1',
                          '净烟气含尘量'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.DUST_B,
                          'DUST_B',
                          _vm.infoList.DUST_B_node_string,
                          'DUST_BVV1'
                        )}}},[_vm._v(" "+_vm._s("DUST_B" in _vm.infoList ? _vm.infoList.DUST_B : 0)+" mg/m³ ")]),_c('div',{staticClass:"leftrow5wd"})])]),_c('div',{staticClass:"cen-co2"},[_c('div',{staticClass:"cen-co2-row1 fl"},[_vm._v("烟气温度")]),_c('div',{staticClass:"cen-co2-row2 fl"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'T_YQ_B',
                          _vm.infoList.T_YQ_B_node_string,
                          'T_YQ_BVV1',
                          '净烟气烟气温度'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.T_YQ_B,
                          'T_YQ_B',
                          _vm.infoList.T_YQ_B_node_string,
                          'T_YQ_BVV1'
                        )}}},[_vm._v(" "+_vm._s("T_YQ_B" in _vm.infoList ? _vm.infoList.T_YQ_B : 0)+" ℃ ")]),_c('div',{staticClass:"leftrow5wd"})])])])]),_c('div',{staticClass:"cen-lable"},[_vm._v("工艺水")]),_c('div',{staticClass:"cen-lable1"},[_vm._v("原烟气")]),_c('div',{staticClass:"cen-lable2"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                    2,
                    'TYQ_Y_B',
                    _vm.infoList.TYQ_Y_B_node_string,
                    'TYQ_Y_BVV1',
                    '原烟气烟气温度'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.TYQ_Y_B,
                    'TYQ_Y_B',
                    _vm.infoList.TYQ_Y_B_node_string,
                    'TYQ_Y_BVV1'
                  )}}},[_vm._v(" "+_vm._s("TYQ_Y_B" in _vm.infoList ? _vm.infoList.TYQ_Y_B : 0)+" ℃ ")])]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵A")]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵B")]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵C")]),_c('div',{staticClass:"cen-lable3"},[_vm._v("循环泵D")])])]),_c('div',{staticClass:"rightmain flex"},[_c('div',{staticClass:"rig-left"},[_c('div',{staticClass:"rig-lable1"},[_vm._v("脱硫塔")]),_c('div',{staticClass:"rig-lable1 rig-jge flex"},[_c('div',{staticClass:"rig-row1"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'T_XST1_B',
                      _vm.infoList.T_XST1_B_node_string,
                      'T_XST1_BVV1',
                      '吸收塔温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.T_XST1_B,
                      'T_XST1_B',
                      _vm.infoList.T_XST1_B_node_stringCEinfoList
                        .T_XST1_B_node_string,
                      'T_XST1_BVV1'
                    )}}},[_vm._v(" "+_vm._s("T_XST1_B" in _vm.infoList ? _vm.infoList.T_XST1_B : 0)+" ℃ ")])]),_c('div',{staticClass:"rig-row1"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'T_XST2_B',
                      _vm.infoList.T_XST2_B_node_string,
                      'T_XST2_BVV1',
                      '吸收塔温度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.T_XST2_B,
                      'T_XST2_B',
                      _vm.infoList.T_XST2_B_node_string,
                      'T_XST2_BVV1'
                    )}}},[_vm._v(" "+_vm._s("T_XST2_B" in _vm.infoList ? _vm.infoList.T_XST2_B : 0)+" ℃ ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge1 flex"},[_c('div',{staticClass:"rig-row1"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LTJYC1_B',
                      _vm.infoList.LTJYC1_B_node_string,
                      'LTJYC1_BVV1',
                      '吸收塔浆液池液位'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.LTJYC1_B,
                      'LTJYC1_B',
                      _vm.infoList.LTJYC1_B_node_string,
                      'LTJYC1_BVV1'
                    )}}},[_vm._v(" "+_vm._s("LTJYC1_B" in _vm.infoList ? _vm.infoList.LTJYC1_B : 0)+" m ")])]),_c('div',{staticClass:"rig-row1"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'LTJYC2_B',
                      _vm.infoList.LTJYC2_B_node_string,
                      'LTJYC2_BVV1',
                      '吸收塔浆液池液位'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.LTJYC2_B,
                      'LTJYC2_B',
                      _vm.infoList.LTJYC2_B_node_string,
                      'LTJYC2_BVV1'
                    )}}},[_vm._v(" "+_vm._s("LTJYC2_B" in _vm.infoList ? _vm.infoList.LTJYC2_B : 0)+" m ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge2 flex",staticStyle:{"margin-left":"0.5vw"}},[_c('div',{staticClass:"rig-row1"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'DTJYC1_B',
                      _vm.infoList.DTJYC1_B_node_string,
                      'DTJYC1_BVV1',
                      '吸收塔浆液池密度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.DTJYC1_B,
                      'DTJYC1_B',
                      _vm.infoList.DTJYC1_B_node_string,
                      'DTJYC1_BVV1'
                    )}}},[_vm._v(" "+_vm._s("DTJYC1_B" in _vm.infoList ? _vm.infoList.DTJYC1_B : 0)+" g/m3 ")])]),_c('div',{staticClass:"rig-row1"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'DTJYC2_B',
                      _vm.infoList.DTJYC2_B_node_string,
                      'DTJYC2_BVV1',
                      '吸收塔浆液池密度'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.DTJYC2_B,
                      'DTJYC2_B',
                      _vm.infoList.DTJYC2_B_node_string,
                      'DTJYC2_BVV1'
                    )}}},[_vm._v(" "+_vm._s("DTJYC2_B" in _vm.infoList ? _vm.infoList.DTJYC2_B : 0)+" g/m3 ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge2 flex"},[_c('div',{staticClass:"rig-row1"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'PH1_B',
                      _vm.infoList.PH1_B_node_string,
                      'PH1_BVV1',
                      '吸收塔浆液池PH值'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.PH1_B,
                      'PH1_B',
                      _vm.infoList.PH1_B_node_string,
                      'PH1_BVV1'
                    )}}},[_vm._v(" "+_vm._s("" in _vm.infoList ? _vm.infoList.PH1_B : 0)+" ph ")])]),_c('div',{staticClass:"rig-row1"},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                      2,
                      'PH2_B',
                      'MCSSOURCE',
                      'PH2_BVV1',
                      '吸收塔浆液池PH值'
                    )},"dblclick":function($event){return _vm.Cclick(
                      _vm.infoList.MCSSOURCE.PH2_B,
                      'PH2_B',
                      'MCSSOURCE',
                      'PH2_BVV1'
                    )}}},[_vm._v(" "+_vm._s("" in _vm.infoList ? _vm.infoList.MCSSOURCE.PH2_B : 0)+" ph ")])])]),_c('div',{staticClass:"rig-lable1 rig-jge2 flex"},[_c('div',{staticClass:"rig-row1"},[_c('div',{staticClass:"down",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toIpt(_vm.infoList.MCSO2NOX.PH_H, '', 'PH_H', 'MCSO2NOX')}}},[_vm._v(" "+_vm._s("" in _vm.infoList ? _vm.infoList.MCSO2NOX.PH_H : 0)+" ")])]),_c('div',{staticClass:"rig-row1"},[_c('div',{staticClass:"down",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toIpt(_vm.infoList.MCSO2NOX.PH_L, '', 'PH_L', 'MCSO2NOX')}}},[_vm._v(" "+_vm._s("" in _vm.infoList ? _vm.infoList.MCSO2NOX.PH_L : 0)+" ")])])])]),_c('div',{staticClass:"rig-right"},[_c('div',{staticClass:"rig-lable1"},[_c('div',{staticClass:"rig-rightjg",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                    2,
                    'T_YQ_B',
                    _vm.infoList.T_YQ_B_node_string,
                    'T_YQ_BVV1',
                    '净烟气烟气温度'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.T_YQ_B,
                    'T_YQ_B',
                    _vm.infoList.T_YQ_B_node_string,
                    'T_YQ_BVV1'
                  )}}},[_vm._v(" "+_vm._s("T_YQ_B" in _vm.infoList ? _vm.infoList.T_YQ_B : 0)+" ℃ ")])]),_c('div',{staticClass:"righ-lable2"},[_vm._v("除雾器冲洗水")]),_c('div',{staticClass:"righ-lable3"},[_c('div',{staticClass:"flex"},[_c('div',[_c('div',{staticClass:"righ-col1 flex"},[_c('div',{staticClass:"righ-col1-lb down",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toIpt(
                          _vm.infoList.SO2SP,
                          '',
                          'SO2SP',
                          _vm.infoList.SO2SP_node_string
                        )}}},[_vm._v(" "+_vm._s("SO2SP" in _vm.infoList ? _vm.infoList.SO2SP : 0)+" ")]),_c('div',{staticClass:"righ-col1-lb",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'SO2SPSJ',
                          _vm.infoList.SO2SPSJ_node_string,
                          'SO2SPSJVV1',
                          '炉外so2实际设定值'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.SO2SPSJ,
                          'SO2SPSJ',
                          _vm.infoList.SO2SPSJ_node_string,
                          'SO2SPSJVV1'
                        )}}},[_vm._v(" "+_vm._s("SO2SPSJ" in _vm.infoList ? _vm.infoList.SO2SPSJ : 0)+" ℃ ")])]),_c('div',{staticClass:"righ-col1 righ-col1-jg flex"},[_c('div',{staticClass:"righ-col1-lb",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'F_SHJY_B',
                          _vm.infoList.F_SHJY_B_node_string,
                          'F_SHJY_BVV1',
                          '石灰浆液流量'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.F_SHJY_B,
                          'F_SHJY_B',
                          _vm.infoList.F_SHJY_B_node_string,
                          'F_SHJY_BVV1'
                        )}}},[_vm._v(" "+_vm._s("F_SHJY_B" in _vm.infoList ? _vm.infoList.F_SHJY_B : 0)+" m3/h ")]),_c('div',{staticClass:"righ-col1-lb righ-col1-jg2",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toCompon(
                          2,
                          'KF_TL_B',
                          _vm.infoList.KF_TL_B_node_string,
                          'KF_TL_BVV1',
                          '脱硫石灰浆液流量控制阀门反馈'
                        )},"dblclick":function($event){return _vm.Cclick(
                          _vm.infoList.KF_TL_B,
                          'KF_TL_B',
                          _vm.infoList.KF_TL_B_node_string,
                          'KF_TL_BVV1'
                        )}}},[_vm._v(" "+_vm._s("KF_TL_B" in _vm.infoList ? _vm.infoList.KF_TL_B : 0)+" % ")])]),_c('div',{staticClass:"righ-col1-jg3 flex"},[_c('div',{staticClass:"bottonx jgxx",style:({
                        background:
                          _vm.infoList.QK0F && !_vm.infoList.QK0F.TS
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.QK0F
                          ? _vm.toDetail(
                              2,
                              'QK0F',
                              _vm.infoList.QK0F_node_string,
                              '',
                              'QK0F设定值'
                            )
                          : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"bottonx",style:({
                        background:
                          _vm.infoList.XK0R && !_vm.infoList.XK0R.RM
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.XK0R
                          ? _vm.toDetail(
                              1,
                              'XK0R',
                              'XK0R',
                              _vm.infoList.XK0R_node_string,
                              '氧含量调节先控'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx",style:({
                        background:
                          _vm.infoList.XK0S && !_vm.infoList.XK0S.RM
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.XK0S
                          ? _vm.toDetail(
                              1,
                              'XK0S',
                              _vm.infoList.XK0S_node_string,
                              '',
                              '氧含量调节先控'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx",style:({
                        background:
                          _vm.infoList.XK0F && !_vm.infoList.XK0F.RM
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){_vm.infoList.XK0F
                          ? _vm.toDetail(
                              1,
                              'XK0F',
                              _vm.infoList.XK0F_node_string,
                              '',
                              '脱硫石灰石调节'
                            )
                          : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"bottonx",style:({
                        background:
                          _vm.infoList.MAN37.RM == 1 || _vm.infoList.MAN40.RM == 1
                            ? '#2AFC30'
                            : 'red',
                      }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANTL', 2)}}},[_vm._v(" A ")])])]),_vm._m(2)]),_vm._m(3)])])])])])]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.flag == 5 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('Cfbcysz',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.flag == 7 ? (_vm.isComShow = true) : (_vm.isComShow = false))?_c('Cfbserve',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.manyManual)?_c('manyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leftrow2"},[_c('div',{staticClass:"fl left-lable"},[_vm._v("1#尿素分配")]),_c('div',{staticClass:"fl left-lable leftjg"},[_vm._v("2#尿素分配")]),_c('div',{staticClass:"fl left-lable leftjg"},[_vm._v("3#尿素分配")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leftrow5"},[_c('div',{staticClass:"fl leftrow5jg3"},[_vm._v("尿素溶液")]),_c('div',{staticClass:"fl leftrow5jg2"},[_vm._v("化水除盐水")]),_c('div',{staticClass:"fl leftrow5jg4"},[_vm._v("石灰石粉")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jange"},[_c('div',{staticClass:"mag-top1"},[_vm._v("石灰石旋流站")]),_c('div',{staticClass:"mag-top2"},[_vm._v("事故浆液罐")]),_c('div',{staticClass:"mag-top3"},[_vm._v("集水坑")]),_c('div',{staticClass:"mag-top1"},[_vm._v("石灰石浆液")]),_c('div',{staticClass:"mag-top4"},[_vm._v("氧化空气")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"righ-lable4 flex"},[_c('div',[_c('div',{staticClass:"jianh"},[_vm._v("石膏排除泵A")]),_c('div',{staticClass:"jianh1"},[_vm._v("石膏排除泵B")])]),_c('div',{staticClass:"jianh3"},[_c('div',{},[_vm._v("工艺水")]),_c('div',{staticClass:"jianh4"},[_vm._v("事故浆液罐")]),_c('div',{staticClass:"jianh4"},[_vm._v("石膏旋流站")])])])}]

export { render, staticRenderFns }